import React from "react"

class ComponentMainHeader extends React.Component {

  render() {

    const props = this.props;
    const elementClass = (props.className === undefined) ? "container main-container mx-auto bg-white" : `container main-container mx-auto bg-white ${props.className}`;

    return (
      <div className={elementClass}>
        {this.props.children}
      </div>
    )
  }
}

export default ComponentMainHeader