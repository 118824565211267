import React from 'react'

import Translate from '../components/translateProvider'
import Header from "../components/header"
import Nav from "../components/navTop"
import SubHeader from "../components/subHeader"

import Footer from "../components/footer"
import Crate from "../components/crate"
import Seo from "../components/seo"

class PageTemplate extends React.Component {

  render() {

    const props = this.props;
    const locale = props.locale;
    const title = props.title;
    const icon = props.icon;

    return (

      <Translate locale={locale}>
        <div>
          <Seo title={title} locale={locale}></Seo>
          <div className="jja-header">
            <Header locale={locale} headerImage={props.headerImage}/>
            <Nav locale={locale}></Nav>
          </div>
          <SubHeader title={title} icon={icon} />
          {this.props.children}
          <Footer locale={locale}></Footer>
        </div>
      </Translate>
    )
  }
}

export default PageTemplate
