import React from "react"
import Crate from "./crate"
import styled from "@emotion/styled"
import { FormattedMessage } from 'react-intl'

class ComponentHeader extends React.Component {

  render() {
    return (
      <Crate className="bg-cyan-100">
        <div className="container px-5 sm:px-0 mx-auto">
          <div className="flex items-center sub-header">
            <h1 className="tk-harfang-pro text-mlg text-purple-900">
              <FormattedMessage id={this.props.title} />
            </h1>
          </div>
        </div>
      </Crate>
    )
  }
}

export default ComponentHeader