import React from "react"
import logo_en from "../assets/educate-magis-en.svg"
import logo_es from "../assets/educate-magis-es.svg"
import logo_fr from "../assets/educate-magis-fr.svg"
import TranslateLink from './translateLink'
import { FormattedMessage } from 'react-intl'
import { FiExternalLink } from "react-icons/fi";

import Crate from '../components/crate';

class ComponentFooter extends React.Component {


  render() {

    const props = this.props;
    const locale = (props.locale === undefined) ? "en" : props.locale;

    const emUrl = (locale === 'en')
      ? 'https://www.educatemagis.org/'
      : `https://www.educatemagis.org/${locale}/`

    // const vc2020Link = (locale === 'en')
    //   ? 'https://www.educatemagis.org/jesedu-jogja2020-virtual-colloquium/'
    //   : 'https://www.educatemagis.org/' + locale + '/jesedu-jogja2020-virtual-colloquium/';

    const colloquiumHubLink = (locale === 'en')
      ? 'https://www.educatemagis.org/jesedu-global2021/colloquium-hub/'
      : 'https://www.educatemagis.org/' + locale + '/jesedu-global2021/colloquium-hub/';

    let logo = logo_en
    if (locale === 'es') {
      logo = logo_es
    }
    else if (locale === 'fr') {
      logo = logo_fr
    }

    return (
      <div>
        <div className="bg-blue-900 py-2" />
        <Crate className="bg-purple-900 mb-8">
          <div className="container mx-auto bg-purple-900">
            <div className="flex flex-wrap items-end">
              <div className="w-full sm:w-1/2">
                <div className="block text-center sm:text-left">
                  <p className="tk-harfang-pro text-mmd text-white mb-3">
                    <FormattedMessage id="JESEDU-Global2021" />
                  </p>
                  <div className="border-solid border-t-2	border-blue-900 my-5 mx-auto sm:mx-0" style={{ maxWidth: '240px' }} />
                  <ul className="text-omd font-semibold text-white mt-3">
                    {/* <li>
                      <a href={vc2020Link} target="_blank" rel="noopener noreferrer">
                        <FormattedMessage id="Virtual Colloquium" />
                        &nbsp;<sup><FiExternalLink className="inline-block" /></sup>
                      </a>
                    </li> */}
                    <li>
                      <TranslateLink to="/">
                        <FormattedMessage id="Theme" />
                      </TranslateLink>
                    </li>
                    <li>
                      <a href={colloquiumHubLink} target="_blank" rel="noopener noreferrer">
                        <FormattedMessage id="Colloquium Hub" />
                        &nbsp;<sup><FiExternalLink className="inline-block" /></sup>
                      </a>
                    </li>
                    {/* <li><TranslateLink to="/page-programme/"> */}
                    {/* <FormattedMessage id="Programme" /> */}
                    {/* </TranslateLink> */}
                    {/* </li> */}
                    <li><TranslateLink to="/page-speakers/">
                      <FormattedMessage id="Speakers" />
                    </TranslateLink>
                    </li>
                    <li><TranslateLink to="/page-experience/">
                      <FormattedMessage id="Experience" />
                    </TranslateLink>
                    </li>
                    {/* <li><TranslateLink to="/page-participants-accommodation/">
                      <FormattedMessage id="Transportation Accommodation" />
                    </TranslateLink>
                    </li> */}
                    {/* <li><TranslateLink to="/page-participants-pilgrimage/">
                      <FormattedMessage id="Pilgrimage" />
                    </TranslateLink>
                    </li> */}
                    <li><TranslateLink to="/page-participants-registration/">
                      <FormattedMessage id="Registration" />
                    </TranslateLink>
                    </li>
                    <li>
                      <TranslateLink to="/page-contact/">
                        <FormattedMessage id="Contact Us" />
                      </TranslateLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="w-full sm:w-1/2 mt-10 sm:mt-0">
                <div className="block text-center sm:text-right">
                  <p className="text-omd font-normal text-white my-1 text-osm">
                    <FormattedMessage id="Supported By" />
                  </p>
                  <a href={emUrl}>
                    <img className="mx-auto sm:ml-full sm:mr-0" style={{ width: `220px` }} src={logo} alt="Logo" />
                  </a>
                  <div className="text-oxs font-normal text-blue-300">
                    <TranslateLink to="/legals-cookie-policy/">
                      <FormattedMessage id="Cookie Policy" />
                    </TranslateLink>
                    &nbsp;|&nbsp;
                    <TranslateLink to="/legals-terms-of-use/">
                      <FormattedMessage id="Terms of Use" />
                    </TranslateLink>
                    &nbsp;|&nbsp;
                    <TranslateLink to="/legals-privacy-statement/">
                      <FormattedMessage id="Privacy Statement" />
                    </TranslateLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Crate>
      </div>
    )
  }
}

export default ComponentFooter