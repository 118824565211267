import React from "react"

class ComponentCrate extends React.Component {

  render() {

    const props = this.props
    const elementStyle = (props.style === undefined) ? {} : props.style
    const elementClass = (props.className === undefined) ? "crate-container-wrapper mx-auto" : `crate-container-wrapper mx-auto ${props.className}`

    return (
      <div className={elementClass} style={elementStyle}>
        <div className="crate-container container w-full mx-auto">
          {this.props.children}
        </div>
      </div>
    )    
  }
}

export default ComponentCrate