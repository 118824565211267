import React from 'react'
import Helmet from "react-helmet"

import { globalHistory } from "@reach/router"
import { injectIntl } from 'react-intl';

class Seo extends React.Component {

  render() {

    const props = this.props;
    const intl = this.props.intl;
    const locale = props.locale;
    const title = props.title;

    const seo = {
      title: intl.formatMessage({ id: title }),
      locale: locale,
      url: globalHistory.location.href,
      // description: '',
      // keywords: '',
    }
    
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <html lang={seo.locale} />
        <title>{ seo.title }</title>
        {/* <meta name="description" content={seo.description} /> */}
        {/* <meta name="keywords" content={seo.keywords} /> */}
        <meta property="og:url" content={globalHistory.location.href} />
        <meta property="og:title" content={seo.title} />
        {/* <meta property="og:description" content={seo.description} /> */}
        {/* <meta property="og:image" content={seo.image} /> */}
        {/* <meta name="twitter:card" content="summary_large_image" /> */}
        {/* <meta name="twitter:title" content={seo.title} /> */}
        {/* <meta name="twitter:description" content={seo.description} /> */}
        {/* <meta name="twitter:image" content={seo.image} /> */}
      </Helmet>
    )
  }
}

export default injectIntl(Seo)
