import React from "react"
import posed from 'react-pose';
import { FormattedMessage } from 'react-intl'
import TranslateLink from './translateLink'
import LanguageSelector from './languageSelectorMobile'
import styled from "@emotion/styled"
import Img from "gatsby-image"
import { FiExternalLink } from "react-icons/fi";

const wrapProps = {
  open: {
    x: "0%",
    delayChildren: 300,
    staggerChildren: 50
  },
  closed: {
    delay: 350,
    staggerChildren: 20,
    x: "-100%"
  }
};

const itemProps = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: 20 }
};

const Wrap = posed.ul(wrapProps)
const Item = posed.li(itemProps)

const wrapStyle = {
  position: `fixed`,
  top: `0`,
  bottom: `0`,
  left: `0`,
  right: `0`,
  zIndex: `550`,
}

const itemStyle = {
  background: `#FFFFFF`,
  borderRadius: `5px`,
  opacity: `0`,
  transformOriginY: `0%`,
}

const Sidebar = styled.div`
  width: 300px;
  background: #eeeeee;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
`

class ComponentNavSide extends React.PureComponent {

  componentWillUnmount() {
    if (this.docExists()) {
      document.body.classList.remove('no-scroll');
    }
  }

  docExists() {
    return typeof document !== 'undefined';
  }

  lockScrollingOnMenuOpen() {
    if (this.docExists()) {
      !this.props.isOpen
        ? document.body.classList.remove('no-scroll')
        : document.body.classList.add('no-scroll');
    }
  }

  render() {
    const props = this.props;
    const locale = (props.locale === undefined) ? "en" : props.locale;
    this.lockScrollingOnMenuOpen();

    // const vc2020Link = (locale === 'en')
    //   ? 'https://www.educatemagis.org/jesedu-jogja2020-virtual-colloquium/'
    //   : 'https://www.educatemagis.org/' + locale + '/jesedu-jogja2020-virtual-colloquium/';

    const colloquiumHubLink = (locale === 'en')
      ? 'https://www.educatemagis.org/jesedu-global2021/colloquium-hub/'
      : 'https://www.educatemagis.org/' + locale + '/jesedu-global2021/colloquium-hub/';

    return (
      <Wrap className="block md:hidden" style={wrapStyle} data-toggle="menu" pose={props.isOpen ? "open" : "closed"} onClick={(e) => { this.props.closeMenu(e) }}>
        <Sidebar>
          <div className="flex flex-col h-full">
            <div className="bg-purple-900 px-2 py-3 flex items-center border-b-8 border-blue-900">
              <div>
                <Img fixed={props.headerImage.childImageSharp.fixed} />
              </div>
              <div className="tk-harfang-pro font-semibold ml-3">
                <p>
                  <span className="block text-white" style={{ fontSize: `16px` }}>
                    <FormattedMessage id="II Colloquium" />
                  </span>
                  <span className="block text-white" style={{ fontSize: `22px` }}>
                    <FormattedMessage id="JESEDU-Global2021" />
                  </span>
                </p>
              </div>
            </div>
            <div className="px-3 py-3 text-purple-900 font-semibold" style={{ overflowY: 'scroll' }}>
              <Item className="mb-3">
                <LanguageSelector locale={locale} />
              </Item>
              <Item className="mb-3 px-1 py-3 " style={itemStyle}>
                <TranslateLink className="px-3 block" to="/">
                  <FormattedMessage id="Theme" />
                </TranslateLink>
              </Item>
              <Item className="mb-3 px-1 py-3 " style={itemStyle}>
                <a href={colloquiumHubLink} className="px-3 block" target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="Colloquium Hub" />
                  &nbsp;<sup><FiExternalLink className="inline-block" /></sup>
                </a>
              </Item>
              {/* <Item className="mb-3 px-1 py-3 " style={itemStyle}> */}
              {/* <TranslateLink className="px-3 block" to="/page-programme/"> */}
              {/* <FormattedMessage id="Programme" /> */}
              {/* </TranslateLink> */}
              {/* </Item> */}
              <Item className="mb-3 px-1 py-3 " style={itemStyle}>
                <TranslateLink className="px-3 block" to="/page-experience/">
                  <FormattedMessage id="Experience" />
                </TranslateLink>
              </Item>
              <Item className="mb-3 px-1 py-3 " style={itemStyle}>
                <TranslateLink className="px-3 block" to="/page-speakers/">
                  <FormattedMessage id="Speakers" />
                </TranslateLink>
              </Item>
              {/* <Item className="mb-3 px-1 py-3 " style={itemStyle}>
                <TranslateLink className="px-3 block" to="/page-participants-accommodation/">
                  <FormattedMessage id="Transportation Accommodation" />
                </TranslateLink>
              </Item> */}
              {/* <Item className="mb-3 px-1 py-3 " style={itemStyle}>
                <TranslateLink className="px-3 block" to="/page-participants-pilgrimage/">
                  <FormattedMessage id="Pilgrimage" />
                </TranslateLink>
              </Item> */}
              <Item className="mb-3 px-1 py-3 " style={itemStyle}>
                <TranslateLink className="px-3 block" to="/page-participants-registration/">
                  <FormattedMessage id="Registration" />
                </TranslateLink>
              </Item>
              <Item className="mb-3 px-1 py-3 " style={itemStyle}>
                <TranslateLink className="px-3 block" to="/page-contact/">
                  <FormattedMessage id="Contact Us" />
                </TranslateLink>
              </Item>
            </div>
          </div>
        </Sidebar>
      </Wrap>
    );
  }
}

export default ComponentNavSide