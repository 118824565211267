module.exports = {
  en: {
    path: 'en',
    locale: 'English',
    default: true
  },
  es: {
    path: 'es',
    locale: 'Français'
  },
  fr: {
    path: 'fr',
    locale: 'Español'
  }
}
