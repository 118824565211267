import React from "react"
import { navigate } from "gatsby"
import { globalHistory } from "@reach/router"

class LanguageSelectorMobile extends React.PureComponent {

  changeLanguage = (event) => {

    // REMOVE LEADING SLASH FROM CURRENT ROUTE
    const lc = event.target.value
    let currentPath = globalHistory.location.pathname.replace(/^\//g, '')

    // REMOVE LANGUAGE FROM CRRENT ROUTE
    if (currentPath.indexOf('es/') === 0) {
      currentPath = currentPath.substring(3)
    }
    if (currentPath.indexOf('fr/') === 0) {
      currentPath = currentPath.substring(3)
    }

    // REBUILD AND SERVE THE NEW ROUTE
    const route = (lc === 'en') ? `/${currentPath}` : `/${lc}/${currentPath}`
    navigate(route)
  };

  render() {

    const props = this.props;
    const locale = (props.locale === undefined) ? "en" : props.locale;

    let localeOptions = [
      { 'lc': 'en', 'label': 'English' },
      { 'lc': 'es', 'label': 'Español' },
      { 'lc': 'fr', 'label': 'Français' },
    ]

    const idx = localeOptions.findIndex(x => x.lc === locale);
    const selectedOption = localeOptions.splice(idx, 1)[0]

    return (
      <select className="block w-full font-semibold bg-white rounded px-1 py-3" onChange={this.changeLanguage}>
        <option value={selectedOption.lc}>{selectedOption.label}</option>
        {localeOptions.map((l, key) => {
          return <option key={key} value={l.lc}>{l.label}</option>
        })}
      </select>
    );
  }
}

export default LanguageSelectorMobile