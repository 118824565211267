import React from "react"
import TranslateLink from './translateLink'
import { FormattedMessage } from 'react-intl'
import Crate from '../components/crate';
import styled from "@emotion/styled"
import { globalHistory } from "@reach/router"
import { FiExternalLink, FiX } from "react-icons/fi";

const NavItems = styled.div`
  > div > span {
    color: #fff;
    transition: all .15s ease-in-out;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }
  > div:hover > span,
  > div:hover > span.nav-active{
    border-bottom: 5px solid transparent;
  }
  > div > span:not(.nav-active):hover{
    cursor: pointer;
  }
  > div > span.nav-active{
    color: #fff;
    border-bottom: 5px solid #00AEEF;
  }
  > div:hover > span:hover{
    border-bottom: 5px solid #00AEEF;
    padding: 0 1rem;
  }
  > div > span > div{
    display:none;
  }
  > div > span:hover > div{
    display: block;
  }
`

const SubMenu = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  min-width: 200px;
  z-index: 999;
  > a {
    background-color: #00AEEF;
  }
`

const itemClass = "px-4 py-1 block";

class ComponentNavTop extends React.Component {

  constructor(props, ...args) {
    super(props, ...args);

    this.state = {
      messageState: 'hide',
    };
  }

  navClass(elClass, paths) {
    const currentPath = globalHistory.location.pathname;
    return paths.includes(currentPath) ? elClass + " nav-active" : elClass;
  }

  toggleMessage = (event) => {
    this.setState({
      messageState: this.state.messageState === 'show' ? 'hide' : 'show'
    });
  }

  render() {

    const props = this.props;
    const locale = (props.locale === undefined) ? "en" : props.locale;
    const messageState = this.state.messageState

    // const vc2020Link = (locale === 'en')
    //   ? 'https://www.educatemagis.org/jesedu-jogja2020-virtual-colloquium/'
    //   : 'https://www.educatemagis.org/' + locale + '/jesedu-jogja2020-virtual-colloquium/';

    const colloquiumHubLink = (locale === 'en')
      ? 'https://www.educatemagis.org/jesedu-global2021/colloquium-hub/'
      : 'https://www.educatemagis.org/' + locale + '/jesedu-global2021/colloquium-hub/';

    return (
      <div>
        <Crate className="hidden md:block">
          <NavItems className="menu-top">
            <div className="flex bg-blue-900 pl-10 rounded-tl-full items-end font-semibold justify-end">
              {/* <span className={itemClass}>
                  <a href={vc2020Link} target="_blank" rel="noopener noreferrer">
                    <sup><FiExternalLink className="inline-block" /></sup>&nbsp;
                    <FormattedMessage id="Virtual Colloquium" />
                  </a>
                </span> */}
              <span className={this.navClass("", ["/"])}>
                <TranslateLink className={itemClass} to="/">
                  <FormattedMessage id="Theme" />
                </TranslateLink>
              </span>
              <span >
                <a href={colloquiumHubLink} className={itemClass} target="_blank" rel="noopener noreferrer">
                  <div class="flex">
                    <FormattedMessage id="Colloquium Hub" />
                    <FiExternalLink className="ml-2" />
                  </div>
                </a>
              </span>
              {/* <span className={this.navClass("", ["/page-programme/"])}>
                  <TranslateLink className={itemClass} to="/page-programme/">
                    <FormattedMessage id="Programme" />
                  </TranslateLink>
                </span> */}
              <span className={this.navClass("", ["/page-experience/"])}>
                <TranslateLink className={itemClass} to="/page-experience/">
                  <FormattedMessage id="Experience" />
                </TranslateLink>
              </span>
              <span className={this.navClass("", ["/page-speakers/"])}>
                <TranslateLink className={itemClass} to="/page-speakers/">
                  <FormattedMessage id="Speakers" />
                </TranslateLink>
              </span>
              {/* <span
                  className={
                    this.navClass(
                      "px-1 py-1 block relative",
                      [
                        "/page-participants-accommodation/",
                        "/page-participants-pilgrimage/",
                        "/page-participants-registration/"
                      ]
                    )}>
                  <FormattedMessage id="Participants" />
                  <SubMenu>
                    <TranslateLink className="block border-b border-white px-3 py-1" to="/page-participants-accommodation/">
                      <FormattedMessage id="Transportation Accommodation" />
                    </TranslateLink>
                    <TranslateLink className="block border-b border-white px-3 py-1" to="/page-participants-pilgrimage/">
                      <FormattedMessage id="Pilgrimage" />
                    </TranslateLink>
                    <TranslateLink className="block px-3 py-1" to="/page-participants-registration/">
                      <FormattedMessage id="Registration" />
                    </TranslateLink>
                  </SubMenu>
                </span> */}
              <span className={this.navClass("", ["/page-participants-registration/"])}>
                <TranslateLink className={itemClass} to="/page-participants-registration/">
                  <FormattedMessage id="Registration" />
                </TranslateLink>
              </span>
              <span className={this.navClass("", ["/page-contact/"])}>
                <TranslateLink className={itemClass} to="/page-contact/">
                  <FormattedMessage id="Contact Us" />
                </TranslateLink>
              </span>
            </div>
          </NavItems>
        </Crate>
      </div >
    )
  }
}

export default ComponentNavTop