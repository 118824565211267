import React from "react"
import { navigate } from "gatsby"
import posed from 'react-pose';
import { globalHistory } from "@reach/router"
import styled from "@emotion/styled"
import { IoMdArrowDropdown } from 'react-icons/io';

const wrapProps = {
  visible: { 
    applyAtStart: { display: 'block' },
    opacity: 1,
    staggerChildren: 150,
  },
  hidden: { 
    applyAtEnd: { display: 'none' },
    opacity: 0.99,
    staggerChildren: 200,
    // delay: 350,
  },
};

const itemProps = {
  hidden: { 
    x: "100",    
    opacity: 0,
  },
  visible: { 
    x: "0",
    opacity: 1,
  },
};

const Dropdown = posed.ul(wrapProps);
const Item = posed.li(itemProps)

const StyledDropdown = styled(Dropdown)`
  white-space:nowrap;
  overflow: hidden;
`;

class LanguageSelector extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
    }
  }

  toggleMenu(){
    this.setState({ isVisible: !this.state.isVisible });
  };

  changeLanguage(lc) {

    // REMOVE LEADING SLASH FROM CURRENT ROUTE
    let currentPath = globalHistory.location.pathname.replace(/^\//g,'')

    // REMOVE LANGUAGE FROM CRRENT ROUTE
    if (currentPath.indexOf('es/') === 0) {
      currentPath = currentPath.substring(3)
    }
    if (currentPath.indexOf('fr/') === 0) {
      currentPath = currentPath.substring(3)
    }

    // REBUILD AND SERVE THE NEW ROUTE
    const route = (lc === 'en') ? `/${currentPath}` : `/${lc}/${currentPath}`
    navigate(route)
  };

  render() {

    const props = this.props;
    const locale = (props.locale === undefined) ? "en" : props.locale;
    const pillClass = "text-white font-semibold text-oxs focus:outline-none border-2 rounded-full mx-1 my-1 px-3 py-1 text-center cursor-pointer";
    const itemClass = "border-cyan-500 inline-block";
    const buttonClass = "border-cyan-900";
    
    let localeOptions = [
      {'lc': 'en', 'label': 'English'}, 
      {'lc': 'es', 'label': 'Español'}, 
      {'lc': 'fr', 'label': 'Français'},
    ]

    const idx = localeOptions.findIndex(x => x.lc === locale);
    const selectedOption = localeOptions.splice(idx,1)[0]

    return (
      <div className="flex" style={{position: `relative`}}>
        <StyledDropdown className="" pose={this.state.isVisible ? 'visible' : 'hidden'}>
          {localeOptions.map((l, key) => {
            return <Item key={key} className={`${pillClass} ${itemClass}`} onClick={(e) => { this.changeLanguage(l.lc) }}>{l.label}</Item>
          })}
        </StyledDropdown>
        <button className={`${pillClass} ${buttonClass}`} onClick={(e) => { this.toggleMenu() }}>
          <span className="mx-2">{selectedOption.label}</span>
          <IoMdArrowDropdown className={`text-cyan-900 inline-block`} style={{ fontSize: `1.4rem` }} />
        </button>
      </div>
    );
  }
}

export default LanguageSelector